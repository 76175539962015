import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import axios from "axios";

@Injectable({ providedIn: "root" })
export class MailService {
  constructor(private http: HttpClient) {}

  public async sendMail(
    fromMail: string,
    fromName: string,
    toMail: string,
    toName: string,
    subject: string,
    plainTextContent: string,
    htmlContent: string
  ): Promise<boolean> {
    const url: string = environment.SendMailFunctionUrl;

    const mailData: any = {
      fromMail: fromMail,
      fromName: fromName,
      toMail: toMail,
      toName: toName,
      subject: subject,
      plainTextContent: plainTextContent,
      htmlContent: htmlContent,
    };

    try {
      const res = await axios.post(url, mailData);
      return res.status == 200 ? true : false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
