import { Component, ElementRef, Renderer2 } from "@angular/core";
import { Location } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent {
  private toggleButton: any;
  public sidebarVisible: boolean = false;

  constructor(
    public location: Location,
    private element: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement.children[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (window.outerWidth > 991) {
          window.document.children[0].scrollTop = 0;
        } else {
          window.document.activeElement!.scrollTop = 0;
        }
        this.sidebarClose();
      });

    this.renderer.listen("window", "scroll", (event) => {
      const number = window.scrollY;
      if (number > 150 || window.pageYOffset > 150) {
        navbar.classList.remove("navbar-transparent");
      } else {
        navbar.classList.add("navbar-transparent");
      }
    });
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    console.log(toggleButton, "toggle");
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    html.classList.add("nav-open");
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }

  scrollToId(id?: string) {
    if (!id) {
      window.scroll(0, 0);
    } else {
      let obj = document.getElementById(id) as HTMLElement;
      if (obj) {
        let currenttop = 0;
        if (obj.offsetParent) {
          do {
            currenttop += obj.offsetTop;
          } while ((obj = obj.offsetParent as HTMLElement));
          const navbarHeight =
            document.querySelector(".navbar")?.clientHeight || 0;
          window.scroll(0, currenttop - navbarHeight + 1);
        }
      }
    }
  }

  navigateToClientAdmin() {
    window.open(environment.ClientAdminUrl, "_blank");
  }
}
