<nav
  class="navbar navbar-expand-lg fixed-top navbar-transparent"
  color-on-scroll="500"
>
  <div class="container">
    <div class="navbar-translate">
      <span class="navbar-brand" (click)="scrollToId()">Save Pesquisas</span>
      <button
        class="navbar-toggler navbar-burger"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="sidebarVisible === false ? sidebarOpen() : sidebarClose()"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
    </div>
    <div class="navbar-collapse mx-auto" id="navbarToggler">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <span class="nav-link" (click)="scrollToId('about')"
            ><i class="nc-icon nc-globe"></i>&nbsp;Sobre Nós</span
          >
        </li>
        <li class="nav-item">
          <span class="nav-link" (click)="scrollToId('services')"
            ><i class="nc-icon nc-book-bookmark"></i>&nbsp;Serviços</span
          >
        </li>
        <li class="nav-item">
          <span class="nav-link" (click)="scrollToId('contact')"
            ><i class="nc-icon nc-circle-10"></i>&nbsp;Contato</span
          >
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a
            (click)="navigateToClientAdmin()"
            target="_blank"
            class="btn btn-danger btn-round"
            >Área do Cliente</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
