import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HomeComponent } from "./components/home/home.component";
import { HomeRoutingModule } from "./home-routing.module";
import { NgxCaptchaModule } from "ngx-captcha";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideHttpClient } from "@angular/common/http";

@NgModule({
  exports: [HomeComponent],
  declarations: [HomeComponent],
  imports: [
    HomeRoutingModule,
    CommonModule,
    NgxCaptchaModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [provideHttpClient()],
})
export class HomeModule {}
