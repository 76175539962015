import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { ReCaptcha2Component } from "ngx-captcha";
import { MailService } from "src/app/core/services/mail.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  protected contactFormGroup: FormGroup = this.formBuilder.group({
    name: ["", Validators.required],
    company: ["", Validators.required],
    email: ["", [Validators.required, Validators.email]],
    subject: ["", Validators.required],
    message: ["", Validators.required],
    recaptcha: ["", Validators.required],
  });

  @ViewChild("captchaElem") captchaElem!: ReCaptcha2Component;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  constructor(
    private formBuilder: FormBuilder,
    private mailService: MailService
  ) {}

  handleSuccess(data: any) {
    console.log(data);
  }

  async onSubmit() {
    if (this.contactFormGroup?.invalid) {
      return;
    }

    const message = this.contactFormGroup?.get("message")?.value;
    const from = this.contactFormGroup?.get("email")?.value;
    const subject = this.contactFormGroup?.get("subject")?.value;
    const name = this.contactFormGroup?.get("name")?.value;
    const company = this.contactFormGroup?.get("company")?.value;

    const result = await this.mailService.sendMail(
      environment.ContactsMailAddress,
      name,
      environment.ContactsMailAddress,
      "Atendimento Save Pesquisas",
      subject,
      "",
      this.buildMessage(name, from, company, message)
    );

    if (result) {
      alert("Obrigado pelo contato! Em breve retornaremos");
    } else {
      alert(
        "Houve um problema com o envio. Por favor, tente novamente mais tarde."
      );
    }
    this.contactFormGroup.reset();
  }

  ngOnInit(): void {}

  private buildMessage(
    name: string,
    email: string,
    company: string,
    message: string
  ): string {
    return `
      <h1>Contato Landing Page</h1>
      <p><strong>Nome:&nbsp;</strong>${name}</p>
      <p><strong>E-mail:&nbsp;</strong>${email}</p>
      <p><strong>Razão Social:&nbsp;</strong>${company}</p>
      <p><strong>Mensagem:&nbsp;</strong>${message}</p>
    `;
  }
}
