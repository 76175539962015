<footer class="footer">
  <div class="container">
    <div class="row mt-5 mb-3 justify-content-md-center">
      <div class="col-lg-6 col-md-12 mb-md-0" class="logo-div">
        <img
          src="../../../../assets/images/logo_preto_transparente.png"
          class="img-fluid logo"
          alt="Logo"
        />
      </div>
      <!--<div class="col-lg-4 col-md-6 mt-4 mb-md-0">
        <h5 class="text-uppercase">ONDE ESTAMOS</h5>
        <ul class="list-unstyled mb-0">
          <li>
            <i class="material-icons" style="color: #035a6d">home</i
            ><span> Avenida Paulista, 12345</span>
          </li>
          <li class="mt-1">
            <i class="material-icons" style="color: #035a6d">location_on</i
            ><span> Bela Vista, São Paulo - SP</span>
          </li>
          <li class="mt-1">
            <i class="material-icons" style="color: #035a6d">search</i
            ><span>CEP: 03145-000</span>
          </li>
        </ul>
      </div>-->
      <div class="col-lg-4 col-md-12 mt-5 mb-md-0">
        <h5 class="text-uppercase">FALE CONOSCO</h5>
        <ul class="list-unstyled mb-0">
          <!--<li>
            <i class="material-icons" style="color: #035a6d">phone_in_talk</i
            ><span> (11) 99207-4916 </span>
          </li>-->
          <li class="mt-1">
            <i class="material-icons" style="color: #035a6d">mail</i
            ><span>&nbsp;save@savepesquisas.com.br</span>
          </li>
          <li class="mt-1">
            <i class="material-icons" style="color: #035a6d">mail</i
            ><span>&nbsp;comercial@savepesquisas.com.br</span>
          </li>
        </ul>
      </div>
      
      <div class="credits col-12 mt-4 d-flex justify-content-center">
        <span class="copyright">
          © {{ today | date : "yyyy" }} Todos os direitos reservados |
          <a href="#" style="color: #777777">Política de Privacidade</a> |
          <a href="#" style="color: #777777">Termos e Condições</a>
        </span>
      </div>
    </div>
  </div>
</footer>
