<div
  class="page-header"
  data-parallax="true"
  style="background-image: url('../../../../../assets/images/background.jpg')"
>
  <div class="filter"></div>
</div>
<div class="main">
  <section id="about" class="text-center my-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mx-auto my-3">
          <h2 class="title">Sobre Nós</h2>
          <h5 class="description">
            Com profissionais que atuam no mercado à mais de 15 anos, a Save
            Pesquisas foi criada com intuito de facilitar o processo de
            recrutamento e seleção, compromissada em reduzir os riscos no
            momento de contratação. 
          </h5>
          <h5 class="description">
            Oferecendo serviços com informações
            assertivas, garantindo agilidade e segurança, através de um sistema
            customizado e tecnológico.
          </h5>
          <br />
        </div>
      </div>
      <div class="row my-5">
        <div class="col-md-4">
          <div class="card card-profile card-plain">
            <div class="card-avatar">
              <a href="#avatar"
                ><img src="../../../../../assets/images/save_1.jpg" alt="..."
              /></a>
            </div>
            <div class="card-block mt-2">
              <a href="#paper-kit">
                <div class="author">
                  <h4 class="card-title">Missão</h4>
                </div>
              </a>
              <p class="card-description text-center mx-3 mt-4">
                Oferecer serviços de pesquisas cadastrais totalmente online, conduzidos por profissionais com ampla
                experiência na área. de forma segura e com total discrição.                
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card card-profile card-plain">
            <div class="card-avatar">
              <a href="#avatar"
                ><img src="../../../../../assets/images/save_2.jpg" alt="..."
              /></a>
            </div>
            <div class="card-block mt-2">
              <a href="#paper-kit">
                <div class="author">
                  <h4 class="card-title">Visão</h4>
                </div>
              </a>
              <p class="card-description text-center mx-3 mt-4">
                Estabelecer autoridade no mercado de pesquisas cadastrais, reduzindo riscos na apuração de novos
                colaboradores de nossos parceiros e clientes, com agilidade, inovação e confidencialidade.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card card-profile card-plain">
            <div class="card-avatar">
              <a href="#avatar"
                ><img src="../../../../assets/images/save_3.jpg" alt="..."
              /></a>
            </div>
            <div class="card-block mt-2">
              <a href="#paper-kit">
                <div class="author">
                  <h4 class="card-title">Valor</h4>
                </div>
              </a>
              <p class="card-description text-center mx-3 mt-4">
                Segurança, confidencialidade, trabalho em equipe, excelência e foco no cliente, são as motivações do
                nosso negócio. Nosso compromisso é conhecer e respeitar nossos parceiros e clientes, exercendo nossa missão,
                visão e valores.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="services" class="section-dark text-center py-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mx-auto my-3">
          <h2 class="title">Opções de Pesquisas</h2>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-md-3 my-3">
          <div class="info">
            <div>
              <img
                src="../../../../../assets/icons/pesquisa_prof.png"
                class="img-fluid"
                alt="Responsive image"
                style="max-width: 69px"
              />
            </div>
            <div class="description">
              <h4 class="info-title">Pesquisa Profissional</h4>
              <p class="description pt-2">
                Em entrevista direta com o departamento pessoal, acompanhamos o
                processo de desligamento de funcionários, levantamos datas de
                admissão e demissão, cargo ocupado, e toda a conduta exercida em
                seu tempo de empresa.
              </p>
            </div>
          </div>
        </div> -->
        <div class="col-md-4 my-3">
          <div class="info">
            <div>
              <img
                src="../../../../../assets/icons/pesquisa_crim.png"
                class="img-fluid"
                alt="Responsive image"
                style="max-width: 69px"
              />
            </div>
            <div class="description">
              <h4 class="info-title">Pesquisa Criminal</h4>
              <p class="description pt-2">
                Levantamento de processos criminais junto aos Órgãos Públicos.
                Com uma equipe qualificada e alta tecnologia, colhemos
                informações onde a pessoa pesquisada figure como réu.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-3">
          <div class="info">
            <div>
              <img
                src="../../../../../assets/icons/pesquisa_com.png"
                class="img-fluid"
                alt="Responsive image"
                style="max-width: 69px"
              />
            </div>
            <div class="description">
              <h4 class="info-title">Pesquisa Comercial PF/PJ</h4>
              <p class="description pt-2">
                Levantamento de títulos protestados, cheques devolvidos e
                negativação junto ao comércio em geral instituições financeiras.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-3">
          <div class="info">
            <div>
              <img
                src="../../../../../assets/icons/pesquisa_cnh.png"
                class="img-fluid"
                alt="Responsive image"
                style="max-width: 69px"
              />
            </div>
            <div class="description">
              <h4 class="info-title">Pesquisa CNH</h4>
              <p class="description pt-2">
                Confirmação de dados e levantamento de pontuação atual em
                relação a Carteira Nacional de Habilitação.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="contact" class="my-3">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12 mx-auto my-3">
          <h2 class="title">Fale Conosco</h2>
        </div>
      </div>
      <div class="row my-3">
        <form
          class="contact-form"
          cForm
          (ngSubmit)="onSubmit()"
          [formGroup]="contactFormGroup"
        >
          <div class="row">
            <div class="col-md-6">
              <label>Nome</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="nc-icon nc-single-02"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome"
                  cFormControl
                  formControlName="name"
                  required
                />
              </div>
              <div
                class="validation-error"
                *ngIf="
                  contactFormGroup.controls['name'].invalid &&
                  (contactFormGroup.controls['name'].dirty ||
                    contactFormGroup.controls['name'].touched)
                "
              >
                <ng-container
                  *ngIf="contactFormGroup.controls['name'].errors as errors"
                >
                  <div *ngIf="errors['required']">Nome é obrigatório!</div>
                </ng-container>
              </div>
            </div>
            <div class="col-md-6">
              <label>Razão Social</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="nc-icon nc-briefcase-24"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Razão Social"
                  cFormControl
                  formControlName="company"
                  required
                />
              </div>
              <div
                class="validation-error"
                *ngIf="
                  contactFormGroup.controls['company'].invalid &&
                  (contactFormGroup.controls['company'].dirty ||
                    contactFormGroup.controls['company'].touched)
                "
              >
                <ng-container
                  *ngIf="contactFormGroup.controls['company'].errors as errors"
                >
                  <div *ngIf="errors['required']">
                    Razão Social é obrigatória!
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-md-6">
              <label>Email</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="nc-icon nc-email-85"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Email@email.com"
                  cFormControl
                  formControlName="email"
                  required
                />
              </div>
              <div
                class="validation-error"
                *ngIf="
                  contactFormGroup.controls['email'].invalid &&
                  (contactFormGroup.controls['email'].dirty ||
                    contactFormGroup.controls['email'].touched)
                "
              >
                <ng-container
                  *ngIf="contactFormGroup.controls['email'].errors as errors"
                >
                  <div *ngIf="errors['required']">Email é obrigatório!</div>
                  <div *ngIf="errors['email']">Formato de email inválido!</div>
                </ng-container>
              </div>
            </div>
            <div class="col-md-6">
              <label>Assunto</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="nc-icon nc-chat-33"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Assunto"
                  cFormControl
                  formControlName="subject"
                  required
                />
              </div>
              <div
                class="validation-error"
                *ngIf="
                  contactFormGroup.controls['subject'].invalid &&
                  (contactFormGroup.controls['subject'].dirty ||
                    contactFormGroup.controls['subject'].touched)
                "
              >
                <ng-container
                  *ngIf="contactFormGroup.controls['subject'].errors as errors"
                >
                  <div *ngIf="errors['required']">Assunto é obrigatório!</div>
                </ng-container>
              </div>
            </div>
          </div>
          <label>Mensagem</label>
          <textarea
            class="form-control"
            rows="4"
            placeholder="Nos diga em que podemos te ajudar..."
            cFormControl
            formControlName="message"
            required
          ></textarea>
          <div
            class="validation-error"
            *ngIf="
              contactFormGroup.controls['message'].invalid &&
              (contactFormGroup.controls['message'].dirty ||
                contactFormGroup.controls['message'].touched)
            "
          >
            <ng-container
              *ngIf="contactFormGroup.controls['message'].errors as errors"
            >
              <div *ngIf="errors['required']">A mensagem é obrigatória!</div>
            </ng-container>
          </div>
          <div class="row mt-4 mb-5">
            <div>
              <ngx-recaptcha2
                #captchaElem
                siteKey="6LcLEDklAAAAAMxfD_wd_8_fKpm9hz_GNlaoDn1k"
                (success)="handleSuccess($event)"
                size="normal"
                hl="pt"
                theme="light"
                type="image"
                formControlName="recaptcha"
              >
              </ngx-recaptcha2>
              <div
                class="validation-error"
                *ngIf="
                  contactFormGroup.controls['recaptcha'].invalid &&
                  (contactFormGroup.controls['recaptcha'].dirty ||
                    contactFormGroup.controls['recaptcha'].touched)
                "
              >
                <ng-container
                  *ngIf="
                    contactFormGroup.controls['recaptcha'].errors as errors
                  "
                >
                  <div *ngIf="errors['required']">
                    A verificação é obrigatória!
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-md-4 mr-auto ml-auto">
              <button
                class="btn btn-danger btn-fill"
                [disabled]="!contactFormGroup.valid"
                cButton
                type="submit"
              >
                Enviar Mensagem
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
